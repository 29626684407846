<template>
  <div>
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item"
        >
          <b-button
            variant="primary float-right"
            @click="addTableLimitModal"
            v-text="$t('add_table_limit')"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <div class="card">
        <div class="card-body">
          <div class="vgt-wrap">
            <div class="vgt-inner-wrap">
              <div class="vgt-responsive game-history-table">
                <table class="vgt-table bordered">
                  <thead>
                    <tr>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('operation')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('table_name')" />
                      </th>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('limit')" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                    >
                      <td class="vgt-left-align">
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          class="mr-1"
                          @click="editTableLimitModal(item)"
                          v-text="$t('edit')"
                        />
                        <b-button
                          variant="outline-danger"
                          size="sm"
                          @click="deleteTableLimit(item.id)"
                          v-text="$t('delete')"
                        />
                      </td>
                      <td class="vgt-left-align">
                        <span v-text="item.table_name" />
                      </td>
                      <td class="vgt-left-align">
                        <span v-text="item.limit" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    <b-modal
      ref="add-table-limit-modal"
      size="lg"
      :title="$t('add_table_limit')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        @submit.stop.prevent="addTableLimitHandleSubmit"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('table_name')"
          label-for="add-table-id"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              style="position:relative; text-align:left;"
            >
              <b-form-select
                id="add-table-id"
                v-model="formData.tableId"
                :options="tableListOptions"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('limit')"
          label-for="add-table-limit"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="add-table-limit"
                v-model="formData.limit"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('limit')"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="addTableLimitHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="addTableLimitHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>
    <b-modal
      ref="edit-table-limit-modal"
      size="lg"
      :title="$t('edit_table_limit')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        @submit.stop.prevent="editTableLimitHandleSubmit"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('limit')"
          label-for="edit-table-limit"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="edit-table-limit"
                v-model="formData.limit"
                autocomplete="off"
                maxlength="20"
                :placeholder="$t('limit')"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="editTableLimitHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="editTableLimitHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>
  </div>
</template>

<script>

import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'

import TableLimit from '@/models/TableLimit'

import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ReportTableLimit',
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      items: [],
      loading: true,
      formData: {
        loading: false,
        id: '',
        tableId: '',
        limit: 500000,
      },
      tableListOptions: [],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    resetFormData() {
      this.formData = {
        loading: false,
        id: '',
        tableId: '',
        limit: 500000,
      }
    },
    addTableLimitModal() {
      this.resetFormData()
      this.$refs['add-table-limit-modal'].show()
    },
    addTableLimitHandleCancel() {
      this.$refs['add-table-limit-modal'].hide()
    },
    async addTableLimitHandleSubmit() {
      try {
        if (this.formData.loading === false) {
          this.formData.loading = true
          const response = await TableLimit.store(this.formData)
          this.formData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
            this.$refs['add-table-limit-modal'].hide()
            this.resetFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.tab !== 'undefined') {
              this.accountTab = response.data.results.tab
            }
            if (typeof response.data.results.field_id !== 'undefined') {
              if (this.formData.account_type !== null) {
                document.getElementById(response.data.results.field_id).focus()
              }
            }
          }
        }
      } catch (e) {
        this.formData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    editTableLimitModal(item) {
      this.resetFormData()
      this.formData.id = item.id
      this.formData.limit = item.limit
      this.$refs['edit-table-limit-modal'].show()
    },
    editTableLimitHandleCancel() {
      this.$refs['edit-table-limit-modal'].hide()
    },
    async editTableLimitHandleSubmit() {
      try {
        if (this.formData.loading === false) {
          this.formData.loading = true
          const response = await TableLimit.update(this.formData)
          this.formData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
            this.$refs['edit-table-limit-modal'].hide()
            this.resetFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.tab !== 'undefined') {
              this.accountTab = response.data.results.tab
            }
            if (typeof response.data.results.field_id !== 'undefined') {
              if (this.formData.account_type !== null) {
                document.getElementById(response.data.results.field_id).focus()
              }
            }
          }
        }
      } catch (e) {
        this.formData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    async loadData() {
      this.loading = true
      const response = await TableLimit.list()
      if (response.data.status) {
        this.items = response.data.data
        this.tableListOptions = response.data.meta.tableListOptions
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: this.$t(response.data.message),
          },
        }, {
          position: 'top-center',
        })
      }
      this.loading = false
    },
    async deleteTableLimit(id) {
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await TableLimit.delete(id)
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
          }
        }
      })
    },
  },
}
</script>
