import axios from '@/libs/axios'

class TableLimit {
  static list(params) {
    return axios.post('user/table-limit', params)
  }

  static store(params) {
    return axios.post('user/table-limit/register', params)
  }

  static update(params) {
    return axios.post('user/table-limit/update', params)
  }

  static delete(limitId, params) {
    return axios.post(`user/table-limit/${limitId}/delete`, params)
  }
}
export default TableLimit
